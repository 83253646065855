<template>
  <div>
    <el-form
      :model="form"
      label-position="top"
    >
      <div class="flex flex-wrap -mx-2">
        <div class="w-1/3 px-2">
          <el-form-group
            name="Full Name"
            label="Full Name"
            :validator="$v.form.full_name"
          >
            <el-input
              v-model="form.full_name"
              placeholder="Full Name"
              @input="$v.form.full_name.$touch()"
            />
          </el-form-group>

          <el-form-group
            name="Email"
            label="Email"
            :validator="$v.form.email"
          >
            <el-input
              v-model="form.email"
              placeholder="Email"
              @blur="$v.form.email.$touch()"
            />
          </el-form-group>

          <el-form-group
            name="No Telepon"
            label="No Telepon"
            :validator="$v.form.phone_number"
          >
            <!-- <el-input
              placeholder="No Telepon"
              v-model="form.phone_number"
            /> -->
            <vue-tel-input
              v-model="form.phone_number"
              wrapper-classes="el-input"
              input-classes="el-input__inner"
              input-id=""
              :disabled-formatting="mode !== 'create'"
              @input="onTelInput"
            />
          </el-form-group>
          <!-- <pre>{{ $v.form.phone_number }}</pre> -->
          <!-- <pre>{{ vti }}</pre> -->
        </div>
        <div class="w-1/3 px-2">
          <el-form-group
            name="Username"
            label="Username"
            :validator="$v.form.username"
          >
            <el-input
              v-model="form.username"
              placeholder="Username"
              :disabled="mode === 'update'"
              @input="$v.form.username.$touch()"
            />
          </el-form-group>
          <el-form-group
            name="Password"
            label="Password (default: 123qweasdzxc)"
            :validator="$v.form.password"
          >
            <el-input
              v-model="form.password"
              placeholder="Password"
              show-password
              :disabled="mode === 'update'"
            />
          </el-form-group>
          
          <el-form-group
            name="Status"
            label="Status"
            :validator="$v.form.status"
          >
            <el-select
              v-model="form.status"
              class="w-full"
              placeholder="Select Status"
              @blur="$v.form.status.$touch()"
            >
              <el-option
                v-for="item in options.status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-group>
        </div>
        <div class="w-1/3 px-2">
          <el-form-group
            name="Role"
            label="Role"
            :validator="$v.form.role_id"
          >
            <el-select
              v-model="form.role_id"
              class="w-full"
              placeholder="Select Role"
              @blur="$v.form.role_id.$touch()"
            >
              <el-option
                v-for="item in options.roles"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-group>

          <el-form-group
            name="Group"
            label="Group"
            :validator="$v.form.groups"
          >
            <el-select
              v-model="form.groups"
              class="w-full"
              placeholder="Select Group"
              multiple
            >
              <el-option
                v-for="item in options.groups"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-group>
          <el-form-group
            class="w-full"
            label="Stream Type"
          >
            <el-switch
              v-model="form.stream_mode"
              active-text="Active (Video)"
              inactive-text="Passive (Image)"
            />
          </el-form-group>
        </div>
      </div>
    </el-form>
    <el-divider />
    <div
      slot="footer"
      class="dialog-footer flex justify-between"
    >
      <div>
        <el-button
          v-if="mode === 'update'"
          type="danger"
          @click="doDelete"
        >
          Delete
        </el-button>
      </div>
      <div>
        <el-button @click="closeDialog">
          Cancel
        </el-button>
        <el-button
          class="ml-auto"
          type="primary"
          :disabled="$v.$invalid"
          @click="doSave"
        >
          Simpan
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { required, requiredIf, email } from "vuelidate/lib/validators";
// import { phoneNumberFormat } from '../../../../utils/customValidators';
const uuidv4 = require('uuid/v4');

export default {
  props: ['id'],
  data() {
    return {
      mode: 'create',
      data: {
        full_name: null,
        users_groups: []
      },
      form: {
        id: null,
        full_name: '',
        email: '',
        phone_number: '',
        stream_mode: false,
        password: '123qweasdzxc',
        status: 0,
        groups: [],
        role_id: ''
      },
      options: {
        groups: [],
        roles: [],
        status: [
          {
            label: 'Active',
            value: 1
          },
          {
            label: 'Inactive',
            value: 0
          },
          {
            label: 'Blocked',
            value: -1
          }
        ]
      },
      vti: {
        valid: false
      }
    }
  },
  created() {
    this.mode = this.$props.id ? 'update' : 'create';
    // this.form.groups = [1,2]
  },
  async mounted() {
    const roles = await this.getRolesData();
    this.options.roles = roles.records.map(role => {
      return {
        value: role.id,
        label: role.name
      }
    })
    const groups = await this.getGroupsData();
    this.options.groups = groups.records.map(group => {
      return {
        value: group.id,
        label: group.name
      }
    })
    if(this.mode === 'create') {
      this.form.id = uuidv4();
    } else {
      this.data = await this.getData();
      // console.log(this.data);
      const { id, full_name, email, phone_number, status, stream_mode, role_id } = this.data;
      this.form.id = id;
      this.form.full_name = full_name;
      this.form.email = email;
      this.form.phone_number = phone_number;
      this.form.status = status;
      this.form.stream_mode = stream_mode === 'active' ? true : false;
      this.form.username = this.data.logins[0].username;
      this.form.role_id = role_id;
      this.data.users_groups.forEach(group => {
        this.form.groups.push(group.group_id)
      });
      // console.log(this.form);
      this.vti.valid = true;
      this.$v.$touch();
      this.$emit('loadingHandler', false);
    }
    // console.log
  },
  validations: {
    form: {
      full_name: { required },
      email: { email },
      status: { required },
      username: {
        required: requiredIf(function () {
          return this.mode === 'create'
        })
      },
      password: {
        required: requiredIf(function () {
          return this.mode === 'create'
        })
      },
      phone_number: {
        phoneNumberFormat: function() {
          return this.vti.valid;
        }
      },
      role_id: { required },
    }
  },
  methods: {
    onTelInput(input, result) {
      this.$v.form.phone_number.$touch();
      // console.log(input);
      this.vti = result;
      // console.log(result);
      // this.form.phone_number = result.number.e164;
    },
    getRolesData() {
      return new Promise((resolve, reject) => {
        this.$request.get('records/roles')
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    getGroupsData() {
      return new Promise((resolve, reject) => {
        this.$request.get('records/groups')
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    createUser() {
      return new Promise((resolve, reject) => {
        const data = {
          id: this.form.id,
          full_name: this.form.full_name,
          email: this.form.email,
          // phone_number: this.form.phone_number,
          phone_number: this.vti.number.e164,
          created_at: null,
          status: this.form.status,
          role_id: this.form.role_id,
          stream_mode: this.form.stream_mode ? 'active' : 'passive'
        }
        this.$request.post('records/users', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    updateUser() {
      return new Promise((resolve, reject) => {
        const data = {
          full_name: this.form.full_name,
          email: this.form.email,
          // phone_number: this.form.phone_number,
          phone_number: this.vti.number ? this.vti.number.e164 : this.form.phone_number,
          updated_at: null,
          status: this.form.status,
          role_id: this.form.role_id,
          stream_mode: this.form.stream_mode ? 'active' : 'passive'
        }
        this.$request.put(`records/users/${this.form.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    createUserGroupMapping() {
      return new Promise((resolve, reject) => {
        // console.log(this.form);
        if(this.form.groups.length > 0) {
          const data = [];
          this.form.groups.forEach(group => {
            data.push({
              id: uuidv4(),
              user_id: this.form.id,
              group_id: group,
            })
          });
          this.$request.post('records/users_groups', data)
            .then(response => {
              resolve(response.data);
            })
            .catch(err => {
              this.$message({
                type: 'error',
                message: err.response.data.message
              });
              reject(err);
            })
        } else {
          resolve('SKIP');
        }
      })
    },
    deleteGroups(groupIds) {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/users_groups/${groupIds.join(',')}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    deleteUser() {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/users/${this.form.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    deleteLogin() {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/logins/${this.form.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    createUserLogin() {
      return new Promise((resolve, reject) => {
        const data = {
          user_id: this.form.id,
          username: this.form.username,
          password: this.form.password
        }
        this.$request.post('records/logins', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      });
    },
    async createData() {
      this.$emit('loadingHandler', true);
      return new Promise(async (resolve, reject) => {
        try {
          await this.createUser();
          await this.createUserGroupMapping();
          await this.createUserLogin();
          this.$emit('loadingHandler', false);
          this.$store.dispatch('RELOAD', 'panel');
          resolve('DONE')
        } catch(err) {
          this.$emit('loadingHandler', false);
          reject(err);
        }
      })
    },
    async updateData() {
      return new Promise(async (resolve) => {
        // console.log(this.data.groups);
        if(this.data.users_groups.length > 0) {
          await this.deleteGroups(this.data.users_groups.map(group => group.id));
        }
        await this.createUserGroupMapping();
        await this.updateUser();
        resolve('DONE')
      })
    },
    async deleteData() {
      return new Promise(async (resolve) => {
        if(this.data.users_groups.length > 0) {
          await this.deleteGroups(this.data.users_groups.map(group => group.id));
        }
        await this.deleteLogin();
        await this.deleteUser();
        this.$store.dispatch('RELOAD', 'panel');
        resolve('DONE')
      })
    },
    getData() {
      return new Promise((resolve, reject) => {
        const searchParams = new URLSearchParams();
        // searchParams.append('join', 'roles');
        searchParams.append('join', 'users_groups');
        searchParams.append('join', 'logins');
        const params = { params: searchParams };
        // console.log(params);
        this.$request.get(`records/users/${this.$props.id}`, params)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    async doSave() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        (this.mode === 'create') ? await this.createData() : await this.updateData();
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    async doDelete() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(async () => {
        this.$emit('loadingHandler', true);
        await this.deleteData();
        this.$emit('loadingHandler', false);
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    }
  }
}
</script>

<style>
.vti__dropdown {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 38px !important;
}
.vue-tel-input {
  border: 1px solid rgba(255,255,255,0.2) !important;
}
.vue-tel-input:hover {
  border: 1px solid rgba(255,255,255) !important;
}
.vue-tel-input:focus {
  border: 1px solid rgba(255,0,0) !important;
}
.is-error .vue-tel-input {
  border: 1px solid #f56c6c !important;
}
.vti__input.el-input__inner {
  height: 38px !important;
}
</style>