<template>
  <div>
    <div class="flex">
      <div id="filter-area">
        <el-input
          v-model="table.filters[0].value"
          placeholder="Search..."
        />
      </div>
      <div
        id="button-area"
        class="ml-auto"
      >
        <el-button
          v-show="$store.getters.getCurrentAuthUser.data.role_id.name === 'Admin'"
          type="primary"
          @click="create"
        >
          Add User
        </el-button>
      </div>
    </div>
    <data-tables-server
      v-loading="table.loading"
      :data="table.data"
      :total="table.total"
      :filters="table.filters"
      :pagination-props="table.pagination.props"
      @query-change="loadData"
    >
      <el-table-column
        label="Name"
        prop="full_name"
        sortable="custom"
      />
      <el-table-column
        label="Role"
        prop="role_id.name"
      />
      <el-table-column
        label="Created At"
        prop="created_at"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ $moment(scope.row.created_at).format('DD MMM YYYY HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="parse.status[scope.row.status].type"
          >
            {{ parse.status[scope.row.status].label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        align="right"
      >
        <template slot-scope="props">
          <el-button
            v-show="$store.getters.getCurrentAuthUser.data.role_id.name === 'Admin'"
            plain
            type="primary"
            icon="el-icon-edit"
            circle
            @click="update(props.row)"
          />
          <el-button
            plain
            type="info"
            icon="el-icon-search"
            circle
            @click="detail(props.row)"
          />
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table: {
        loading: false,
        data: [],
        params: {},
        queryInfo: null,
        total: 0,
        filters: [
          {
            value: null,
            op: 'cs',
            search_prop: 'full_name'
          }
        ],
        props: {
          border: true
        },
        pagination: {
          props: {
            pageSizes: [5, 10],
            background: true
          }
        }
      },
      parse: {
        status: {
          '-1': {
            label: 'Blocked',
            type: 'danger'
          },
          '0': {
            label: 'Inactive',
            type: 'info'
          },
          '1': {
            label: 'Active',
            type: 'success'
          }
        }
      }
      
    }
  },
  mounted() {},
  methods: {
    async render() {
      // this.table.data = await this.getUsersData();
    },
    async loadData(queryInfo) {
      this.table.loading = true;
      this.table.queryInfo = queryInfo;
      this.filterHandler();
      this.orderHandler();
      this.pageHandler();
      let { records, results } = await this.getUsersData(this.table.params);
      this.table.data = records;
      this.table.total = results;
      this.table.loading = false;
    },
    pageHandler() {
      const queryInfo = this.table.queryInfo;
      this.table.params.page = [queryInfo.page, queryInfo.pageSize].join(',');
    },
    orderHandler() {
      const queryInfo = this.table.queryInfo;
      // console.log(queryInfo);
      this.table.params.order = ['created_at', 'desc'].join(',');
      if(queryInfo.type === 'sort') {
        const orderBy = queryInfo.sort.order === 'ascending' ? 'asc' : 'desc';
        this.table.params.order = [queryInfo.sort.prop, orderBy].join(',');
      }
    },
    filterHandler() {
      const queryInfo = this.table.queryInfo;
      this.table.params.filter = [queryInfo.filters[0].search_prop, queryInfo.filters[0].op, queryInfo.filters[0].value].join(',');
    },
    getUsersData(params = {}) {
      // console.log(params);
      const searchParams = new URLSearchParams();
      searchParams.append('join', 'roles');
      // searchParams.append('join', 'groups');
      // searchParams.append('join', 'users_groups');
      // searchParams.append('join', 'users_roles');
      // searchParams.append('join', 'logins');
      searchParams.append('page', params.page);
      searchParams.append('filter', params.filter);
      searchParams.append('order', params.order);
      return new Promise((resolve, reject) => {
        this.$request.get('records/users', { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    create() {
      const data = {
        type: 'create'
      }
      this.$emit('openDialog', data);
    },
    detail(row) {
      const data = {
        type: 'detail',
        data: row
      }
      this.$emit('openDialog', data);
    },
    update(row) {
      const data = {
        type: 'update',
        data: row
      }
      this.$emit('openDialog', data);
    }
  }
}
</script>